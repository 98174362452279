<template>
<div class="card mb-4">
  <div class="card-body">
    <strong class="mb-4">Zahlungsart</strong>
    <div v-for="(type, index) in paymentAmount.types" :key="index" class="col-sm-12 mb-4">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          :value="type.id"
          :checked="paymentAmount.selected === type.id"
          @change="onSelectPaymentAmount"
          :id="'pm-' + index">
        <label class="form-check-label" :for="'pm-' + index">
          {{ type.name }} <Tooltip v-if="type.tooltip" :tooltip="type.tooltip" class="float-right ml-1"><i class="fa fa-info-circle"></i></Tooltip><br/>{{ type.label }}
        </label>
      </div>
    </div>
    <strong class="mb-4">Wie möchten Sie bezahlen?</strong>
    <div v-for="(type, index) in paymentsSorting" :key="index" class="col-sm-12 mt-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            v-model="payment.selected"
            :value="type.id"
            :id="type.label"
            @click="changePaymentType"
            :disabled="type.disabled">
          <label class="form-check-label" :for="type.label">
            {{ type.label }}
            <Tooltip v-if="type.tooltip" :tooltip="type.tooltip" class="float-right ml-1"><i class="fa fa-info-circle"></i></Tooltip>
          </label>
        </div>
      <div class="float-end">
        <img :src="type.icon" class="img-fluid" width="75px">
      </div>

      <div class="text-muted"><small>{{ type.description }}</small></div>
    </div>
  </div>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()
    // computed
    const paymentAmount = computed(() => {
      return store.getters['checkout/paymentAmount']
    })
    const payment = computed(() => {
      return store.getters['checkout/payment']
    })
    const price = computed(() => {
      return store.getters['checkout/price']
    })
    const paymentType = computed(() => {
      return store.getters['checkout/paymentType']
    })
    const paymentsSorting = computed(() => {
      return payment.value.types.sort((a, b) => {
        return a - b
      })
    })
    // actions
    function onSelectPaymentAmount (e) {
      const val = Number.parseInt(e.target.value)
      if (val === 2) {
        store.dispatch('checkout/changePayment', { disabled: true, item: 2 }) // disabled paypal
      } else {
        store.dispatch('checkout/changePayment', { disabled: false, item: 2 }) // disabled paypal
      }
      store.dispatch('checkout/selectPaymentAmount', val)
    }
    function changePaymentType (e) {
      if (paymentAmount.value.selected === 2) {
        store.dispatch('checkout/changePayment', { disabled: true, item: 2 })
        store.dispatch('checkout/changePaymentType', 1)
      } else {
        store.dispatch('checkout/changePaymentType', e)
      }
    }
    return {
      paymentAmount,
      payment,
      price,
      paymentType,
      paymentsSorting,
      onSelectPaymentAmount,
      changePaymentType
    }
  }
}
</script>
